<template>
  <el-dialog title="导入数据" v-model="visible" width="560px" @close="handleClose">
    <el-upload
      drag
      ref="uploadRef"
      :auto-upload="false"
      :on-success="handleSuccess"
      action="/api/ConnectPlan/Import"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    >
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        点击或将文件拖拽到这里上传
      </div>
    </el-upload>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { useStore  } from 'vuex';
import { ElMessage } from 'element-plus';
export default {
  name: 'DialogImport',
  props: { },
  setup() {
    const store = useStore();

    const state = reactive({
      visible: false,
    });
    const uploadRef = ref(null);
    // 开启弹窗
    const open = () => {
      state.visible = true
    }

    const handleClose = () => {
      state.visible = false
    }
    const submitForm = () => {
      uploadRef.value.submit();
    }

    const handleSuccess = () => {
      ElMessage.success('上传成功！');
      store.dispatch('connectPlan/queryData', {
        page: {
          page: 1
        }
      })
    }

    return {
      ...toRefs(state),
      open,
      uploadRef,
      submitForm,
      handleClose,
      handleSuccess
    }
  }
}
</script>

<style lang="scss" scoped>
</style>