const arrToObj = data => {
  return Object.fromEntries(data.map(item => [item.value, item.label]));
};
/**
 * 组件品牌 brandName
 */
export const BRAND_NAME_TYPES = [
  {
    label: '爱士惟',
    value: '爱士惟'
  },
  {
    label: '古瑞瓦特',
    value: '古瑞瓦特'
  },
  {
    label: '锦浪',
    value: '锦浪'
  },
  {
    label: '奥太',
    value: '奥太'
  }
];
export const BRAND_NAME_TYPES_TEXT = arrToObj(BRAND_NAME_TYPES);

/**
 * 组件型号 comModel
 */
export const COM_MODAL_TYPE = [
  {
    label: '540',
    value: '540'
  },
  {
    label: '545',
    value: '545'
  },
  {
    label: '550',
    value: '550'
  },
  {
    label: '600',
    value: '600'
  }
];
export const COM_MODAL_TYPE_TEXT = arrToObj(COM_MODAL_TYPE);
