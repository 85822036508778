<template>
  <el-dialog :title="type === 'add' ? '添加接线方案' : '编辑接线方案'" v-model="visible" width="560px" @close="handleClose"
    custom-class="dialog" :close-on-click-modal="false">
    <el-form :model="ruleForm" :rules="rules" ref="formRef" label-width="100px" class="good-form" v-loading="loading">
      <el-form-item label="逆变器品牌" prop="brandName">
        <el-select v-model="ruleForm.brandName" placeholder="请选择逆变器品牌">
          <el-option label="全部" :value="0" />
          <el-option v-for="option in BRAND_NAME_TYPES" :key="option.value" :label="option.label"
            :value="option.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="逆变器型号" prop="inverterType">
        <el-input v-model="ruleForm.inverterType" placeholder="请输入逆变器型号" />
      </el-form-item>
      <el-form-item label="逆变器功率" prop="inverterPower">
        <el-input type="number" v-model="ruleForm.inverterPower" placeholder="请输入逆变器功率" />
      </el-form-item>
      <el-form-item label="组件数量" prop="comCount">
        <el-input type="number" v-model="ruleForm.comCount" placeholder="请输入组件数量" />
      </el-form-item>
      <el-form-item label="组件功率" prop="comPower">
        <el-select v-model="ruleForm.comPower" placeholder="请选择组件功率">
          <el-option v-for="option in COM_MODAL_TYPE" :key="option.value" :label="option.label" :value="option.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="组件总功率" prop="comTotalPower">
        <el-input type="number" v-model="comTotalPower" disabled placeholder="=组件功率*组件数量" />
      </el-form-item>
      <el-form-item label="容配比" prop="volRatio">
        <el-input type="number" v-model="volRatio" disabled placeholder="组件总功率/逆变器功率" />
      </el-form-item>
      <el-form-item label="MTTP数量" prop="mttpQuantity">
        <el-input type="number" v-model="ruleForm.mttpQuantity" placeholder="请输入逆变器MTTP分区数量" />
      </el-form-item>
      <el-form-item label="组串输量" prop="stringCount">
        <el-input v-model="ruleForm.stringCount" placeholder="请输入每个组件串接口数量，用'+'隔开" />
      </el-form-item>
      <el-form-item label="接线方案" prop="wiringScheme">
        <el-input v-model="ruleForm.wiringScheme" placeholder="请输入接线方案，用','隔开" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
import { BRAND_NAME_TYPES, COM_MODAL_TYPE } from '../const';
import { calculator } from '@tmaito/utils';
export default {
  name: 'DialogAddHotGood',
  props: {
    type: String
  },
  setup() {
    const store = useStore();
    const formRef = ref(null);
    const initialValue = {
      brandName: '',
      comCount: null,
      comPower: null,
      comTotalPower: null,
      inverterType: null,
      inverterPower: null,
      volRatio: null,
      mttpQuantity: null,
      stringCount: null,
      wiringScheme: null
    }
    const state = reactive({
      visible: false,
      id: '',
      ruleForm: { ...initialValue },
      rules: { }
    });

    // 开启弹窗
    const open = (data) => {
      state.visible = true
      if (data && data?.id) {
        state.id = data?.id;
        console.log(data);
        state.ruleForm = {
          ...data
        }
      } else {
        state.ruleForm = { ...initialValue }
      }
    }

    const handleClose = () => {
      state.visible = false
      formRef.value.resetFields()
    }
    const submitForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          const comTotalPower = calculator.times(state.ruleForm.comPower || 0, state.ruleForm.comCount || 0);
          // store.dispatch('connectPlan/addOrUpdate', {
          store.dispatch('connectPlan/Add', {
            ...state.ruleForm,
            volRatio: (state.ruleForm.inverterPower || 0) !== 0 ? calculator.divide(comTotalPower || 0, state.ruleForm.inverterPower) : 0,
            comTotalPower,
            id: state.id
          }).then(res => {
            if (res.code === 200) {
              handleClose();
              state.visible = false
            }
          });
        } else {
          return false
        }
      })
    }

    const handleAtlasSuccess = (response) => {
      state.ruleForm.atlas = response.data;
    }

    const handleBomSuccess = (response) => {
      state.ruleForm.bom = response.data;
    }

    const comTotalPower = computed(() => calculator.times(state.ruleForm.comPower || 0, state.ruleForm.comCount || 0));

    return {
      ...toRefs(state),
      open,
      formRef,
      submitForm,
      handleClose,
      loading: computed(() => store?.state?.connectPlan?.loading),
      volRatio: computed(() => (state.ruleForm.inverterPower || 0) !== 0 ? calculator.divide(comTotalPower.value || 0, state.ruleForm.inverterPower) : ''),
      comTotalPower,
      COM_MODAL_TYPE,
      BRAND_NAME_TYPES,
      handleBomSuccess,
      handleAtlasSuccess,
    }
  }
}
</script>

<style lang="scss" scoped></style>