<template>
  <div class="filter">
    <el-form ref="formRef" :model="form" inline>
      <el-form-item prop="brandName">
        <el-select v-model="form.brandName" placeholder="请选择逆变器品牌">
          <el-option label="全部" value="" />
          <el-option v-for="option in BRAND_NAME_TYPES" :key="option.value" :label="option.label"
            :value="option.value" />
        </el-select>
      </el-form-item>
      <el-form-item prop="comPower">
        <el-select v-model="form.comPower" placeholder="请选择组件功率">
          <el-option label="全部" :value="0" />
          <el-option v-for="option in COM_MODAL_TYPE" :key="option.value" :label="option.label" :value="option.value" />
        </el-select>
      </el-form-item>
      <el-form-item prop="comCount">
        <el-input v-model="form.comCount" placeholder="请输入组件数量" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit(formRef)" :loading="loading">查询</el-button>
        <el-button @click="resetForm(formRef)" :loading="loading">重置</el-button>
        <el-button type="primary" @click="handleAdd()">添加接线方案</el-button>
        <el-button type="primary" @click="handleImport()">导入数据</el-button>
        <el-button type="primary" @click="handleExport()">导出数据</el-button>
      </el-form-item>
    </el-form>
    <Dialog ref="dialogRef" type="add" />
    <Import ref="importRef" />
  </div>
</template>

<script setup>
import { useStore  } from 'vuex';
import { ref, computed, reactive } from 'vue';
import Dialog from './dialog.vue';
import Import from './import.vue';
import { BRAND_NAME_TYPES, COM_MODAL_TYPE } from '../const';
const store = useStore();
const formRef = ref();
const dialogRef = ref(null);
const importRef = ref(null);
const form = reactive({
  brandName: '',
  comPower: 0,
  comCount: null,
});

store.dispatch('connectPlan/queryData');

const loading = computed(() => store?.state?.connectPlan?.loading);
const onSubmit = (formEl) => {
  if (!formEl) return;
  store.dispatch('connectPlan/queryData', {
    page: { page: 1 },
    filterParams: { ...form }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields();
  store.dispatch('connectPlan/queryData', {
    page: { page: 1 },
    filterParams: { ...form  }
  })
}

const handleAdd = () => {
  dialogRef.value.open();
}

const handleImport = () => {
  importRef.value.open();
}

const handleExport = () => {
  console.log(form, 'form555555555')
  store.dispatch('connectPlan/export', form);
}

</script>

<style lang="scss" scoped>
</style>
